:root {
  font-size: 16px;
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
}

body {
  height: 100vh;
}

*,
body,
html {
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0 20px;
  }
}

// Footer section.
.footer {
  text-align: center;
  padding: 5px;
  font-size: 1.2rem;
  color: $secondary;
}

// 404 page
.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
  h1 {
    font-size: 3rem;
    color: $primary-text;
  }
  div {
    font-size: 2rem;
    font-weight: 300;
    color: $secondary;
    a {
      color: $primary;
      @include generic-transition(color);
      &:hover {
        color: $primary-light;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    h1 {
      font-size: 3rem;
    }
  }
}
