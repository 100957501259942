.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  text-align: center;

  &__title {
    font-size: 6rem;
    max-width: 850px;
    color: $primary-text;
    span {
      color: $primary;
    }
  }

  &__tagline {
    font-size: 1.5rem;
    margin-top: $margin;
    color: $primary-text;
    span {
      font-size: 1.6rem;
    }
  }

  &__actions {
    display: flex;
    margin-top: $margin * 2;
  }

  &__workshops-link {
    text-decoration: none;
    @include button();
    margin-right: $margin;
  }
  &__hire-us-link {
    @include button();
    text-decoration: none;
    background-color: $primary;
    color: white;
    &:hover {
      color: $primary;
      background-color: white;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    max-width: 90%;
    margin: 0 auto;
    height: 50vh;

    &__title {
      font-size: 3rem;
    }

    &__actions {
      width: 100%;
      flex-direction: column;
    }
    &__workshops-link {
      margin: 0;
      margin-bottom: $margin / 2;
    }
    &__actions {
      margin-top: $margin;
    }
  }
}
