@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  scroll-behavior: smooth;
  font-size: 16px;
}

html {
  overflow-x: hidden;
}

body {
  height: 100vh;
}

*, body, html {
  font-family: Roboto, sans-serif;
}

a {
  text-decoration: none;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

@media (width >= 320px) and (width <= 767px) {
  .container {
    padding: 0 20px;
  }
}

.footer {
  text-align: center;
  color: #222;
  padding: 5px;
  font-size: 1.2rem;
}

.not-found-page {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  display: flex;
}

.not-found-page h1 {
  color: #444;
  font-size: 3rem;
}

.not-found-page div {
  color: #222;
  font-size: 2rem;
  font-weight: 300;
}

.not-found-page div a {
  color: #159dd9;
  transition: color .5s ease-in-out;
}

.not-found-page div a:hover {
  color: #159dd999;
}

@media (width >= 320px) and (width <= 767px) {
  .not-found-page h1 {
    font-size: 3rem;
  }
}

.button {
  cursor: pointer;
  border: 1px solid #159dd9;
  border-radius: 15px;
  padding: 20px;
  font-size: 1.6rem;
  transition: all .5s ease-in-out;
}

.button--dark {
  color: #fff;
  background-color: #159dd9;
}

.button--dark:hover, .button--light {
  color: #159dd9;
  background-color: #fff;
}

.button--light:hover {
  color: #fff;
  background-color: #159dd9;
}

.button--full {
  width: 100%;
}

.navigation {
  z-index: 0;
  background-color: #fff;
}

.navigation .desktop-nav {
  padding: 20px 0;
}

.navigation .desktop-nav .container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navigation .navigation__items {
  flex-direction: row;
  align-items: center;
  font-size: 1.3rem;
  display: flex;
}

.navigation .navigation__item {
  margin-right: 25px;
  text-decoration: none;
}

.navigation .navigation__item .navigation__link {
  color: #444;
  text-decoration: none;
  transition: all .25s ease-in-out;
}

.navigation .navigation__item .navigation__link:hover {
  color: #159dd9;
}

.section {
  text-align: center;
  background-color: #fff;
  margin: 50px 0;
  padding: 20px 0;
  font-size: 1.6rem;
}

.section__title {
  color: #159dd9;
  margin-bottom: 25px;
  font-size: 3.2rem;
}

.section__description {
  color: #3c3c3c;
  margin-bottom: 50px;
  font-size: 1.6rem;
}

.section--dark {
  background-color: #159dd9;
}

.section--dark .section__title, .section--dark .section__description {
  color: #fff;
}

.circle {
  z-index: 1000;
  background: #159dd999;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  position: absolute;
  overflow: hidden;
}

.circle--top {
  bottom: calc(100% - 360px);
  left: calc(100% - 150px);
}

.circle--bottom {
  top: calc(100% - 280px);
  left: -150px;
}

@media (width <= 960px) {
  .circle {
    display: none;
  }
}

.home {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.home__title {
  color: #444;
  max-width: 850px;
  font-size: 6rem;
}

.home__title span {
  color: #159dd9;
}

.home__tagline {
  color: #444;
  margin-top: 50px;
  font-size: 1.5rem;
}

.home__tagline span {
  font-size: 1.6rem;
}

.home__actions {
  margin-top: 100px;
  display: flex;
}

.home__workshops-link {
  color: #159dd9;
  background-color: #fff;
  border: 1px solid #159dd9;
  margin-right: 50px;
  padding: 20px;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .5s ease-in-out;
}

.home__workshops-link:hover {
  color: #fff;
  background-color: #159dd9;
}

.home__hire-us-link {
  color: #fff;
  background-color: #159dd9;
  border: 1px solid #159dd9;
  padding: 20px;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .5s ease-in-out;
}

.home__hire-us-link:hover {
  color: #159dd9;
  background-color: #fff;
}

@media (width >= 320px) and (width <= 767px) {
  .home {
    max-width: 90%;
    height: 50vh;
    margin: 0 auto;
  }

  .home__title {
    font-size: 3rem;
  }

  .home__actions {
    flex-direction: column;
    width: 100%;
  }

  .home__workshops-link {
    margin: 0 0 25px;
  }

  .home__actions {
    margin-top: 50px;
  }
}

.workshops {
  max-width: 800px;
  margin: 0 auto;
}

.workshops__item {
  color: #159dd9;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.workshops__item .workshops__info {
  text-align: start;
}

.workshops__item .workshops__info .workshops__title {
  color: #159dd9;
  padding: 20px 0;
  font-size: 3.2rem;
}

.workshops__item .workshops__info .workshops__description {
  color: #444;
}

.workshops__item .workshops__image {
  text-align: end;
  width: 150px;
}

.workshops__item:not(:last-child) {
  margin-bottom: 50px;
}

.workshops__item:nth-child(2n) .workshops__info {
  text-align: end;
  order: 1;
}

@media (width >= 320px) and (width <= 767px) {
  .workshops__item {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .workshops__item .workshops__info {
    text-align: center;
  }

  .workshops__item .workshops__info:nth-child(odd) {
    text-align: center;
    order: 0;
  }
}

.nerds {
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.nerds__image {
  filter: grayscale(.8);
  border-radius: 50%;
  width: 20rem;
  height: 20rem;
}

.nerds__link {
  position: relative;
}

.nerds__overlay {
  z-index: 2;
  border-radius: 50%;
  transition: opacity .5s ease-in-out;
  position: absolute;
  inset: 0;
}

.nerds__overlay .nerds__text {
  color: #fff;
  text-align: center;
  font-size: 3.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width >= 320px) and (width <= 767px) {
  .nerds {
    grid-template-columns: 1fr;
  }
}

.videos {
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  max-width: 800px;
  margin: 0 auto;
  display: grid;
}

.videos__image {
  filter: grayscale(50%);
  width: 300px;
  transition: filter .5s ease-in-out;
}

.videos__image:hover {
  filter: grayscale(0%);
}

@media (width >= 320px) and (width <= 767px) {
  .videos {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .videos__image {
    width: 250px;
  }
}

.contact__input {
  box-sizing: border-box;
  border: 1px solid #159dd999;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1.6rem;
}

.contact__input:focus {
  border: 1.5px solid #159dd9;
  outline: none;
}

.contact__textarea {
  box-sizing: border-box;
  border: 1px solid #159dd999;
  border-radius: 10px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 50px;
  padding: 10px;
  font-size: 1.6rem;
}

.contact__textarea:focus {
  border: 1.5px solid #159dd9;
  outline: none;
}

.social {
  text-align: center;
  color: #fff;
  background-color: #159dd9;
  margin: auto;
  padding: 10px;
  font-size: 1.6rem;
}

.social__image {
  fill: #fff;
  width: 2.4rem;
  margin-right: 50px;
  transition: filter .5s ease-in-out;
}

.social__image:hover {
  filter: opacity(.8);
}

@media (width >= 320px) and (width <= 767px) {
  .social {
    justify-content: space-around;
    display: flex;
  }

  .social__image {
    margin: 0;
  }
}
/*# sourceMappingURL=404.148c7f2a.css.map */
