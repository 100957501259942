@mixin generic-transition($attr) {
  transition: $attr 0.5s ease-in-out;
}

@mixin button {
  color: $primary;
  background-color: white;
  font-size: 1.6rem;
  padding: 20px 20px;
  border: $primary 1px solid;
  @include generic-transition(all);
  &:hover {
    background-color: $primary;
    color: white;
  }
}
