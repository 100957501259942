.workshops {
  max-width: $section-max-width;
  margin: 0 auto;

  &__item {
    color: $primary;
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .workshops__info {
      text-align: start;
      .workshops__title {
        color: $primary;
        font-size: $font-size * 2;
        padding: $padding 0;
      }
      .workshops__description {
        color: $primary-text;
      }
    }

    .workshops__image {
      width: $image-size / 2;
      text-align: end;
    }

    &:not(:last-child) {
      margin-bottom: $margin;
    }
  }
  &__item:nth-child(even) {
    .workshops__info {
      order: 1;
      text-align: end;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    &__item {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .workshops__info {
        text-align: center;
      }
      .workshops__info:nth-child(odd) {
        order: 0;
        text-align: center;
      }
    }
  }
}
