.nerds {
  display: grid;
  grid-gap: $grid-gap;
  grid-template-columns: repeat(2, 1fr);

  &__image {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    filter: grayscale(0.8);
  }

  &__link {
    position: relative;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    z-index: 2;
    @include generic-transition(opacity);

    .nerds__text {
      color: white;
      font-size: $font-size * 2;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
