.social {
  background-color: $primary;
  text-align: center;
  color: white;
  font-size: $font-size;
  margin: auto;
  padding: $padding / 2;

  &__image {
    width: $font-size * 1.5;
    fill: white;
    margin-right: $margin;
    @include generic-transition(filter);

    &:hover {
      filter: opacity(0.8);
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
    justify-content: space-around;
    &__image {
      margin: 0;
    }
  }
}
