.section {
  text-align: center;
  background-color: white;
  font-size: $font-size;
  padding: $padding 0;
  margin: $margin 0;

  &__title {
    color: $primary;
    font-size: $font-size * 2;
    margin-bottom: $margin / 2;
  }

  &__description {
    color: lighten($secondary, 10);
    font-size: $font-size;
    margin-bottom: $margin;
  }

  &--dark {
    background-color: $primary;
    .section__title,
    .section__description {
      color: white;
    }
  }
}
